import { Emitter } from '../emitter';

export enum PeerEvent {
  LocalIceCandidate = 'LocalIceCandidate',
  IceTrickleComplete = 'IceTrickleComplete',
  StreamAvailable = 'StreamAvailable',
  Closed = 'Closed',
  Timeout = 'Timeout',
  TimeoutRecovered = 'TimeoutRecovered',
}

export class Peer extends Emitter {
  
  private connection: RTCPeerConnection;
  private addedStream = false;
  private packetsReceived = 0;
  private packetsLastReceivedAt: number;
  private timedOut = false;
  private closed = true;
  private streamDataTimeoutSeconds: number;

  constructor () {
    super();
  }

  public init (config: RTCConfiguration,  streamDataTimeoutSeconds: number) {
    if (!this.closed) {
      this.close();
    }

    this.streamDataTimeoutSeconds = streamDataTimeoutSeconds;
    this.connection = new RTCPeerConnection(config);
    this.connection.onconnectionstatechange = this.onConnectionStateChange.bind(this);
    this.connection.onsignalingstatechange = this.onSignalingStateChange.bind(this);
    this.connection.ontrack = this.onTrack.bind(this);
    this.connection.onicecandidate = this.onLocalIceCandidate.bind(this);
    this.connection.onnegotiationneeded = this.onNegotiationNeeded.bind(this);
    this.closed = false;
  }

  public close () {
    if (this.closed) return;

    this.closed = true;
    this.timedOut = false;
    this.addedStream = false;
    this.connection.onconnectionstatechange = () => {};
    this.connection.onsignalingstatechange = () => {};
    this.connection.ontrack = () => {};
    this.connection.onicecandidate = () => {};
    this.connection.close();
    this.connection = undefined;
    this.emit(PeerEvent.Closed);
  }

  public getConnection () {
    return this.connection;
  }

  public async handleOfferResponse (description: RTCSessionDescription) {
    return this.connection.setRemoteDescription(description);
  }

  public async handleRemoteIceCandidate (candidate: RTCIceCandidate) {
    return this.connection.addIceCandidate(candidate);
  }

  public createOffer ({
    offerToReceiveVideo,
    offerToReceiveAudio
  }: {
    offerToReceiveVideo: boolean,
    offerToReceiveAudio: boolean
  }) {
    return this.connection.createOffer({
      iceRestart: false,
      offerToReceiveAudio,
      offerToReceiveVideo,
    }).then(async (description) => {
      await this.connection.setLocalDescription(description);
      return this.connection.localDescription.sdp;
    });
  }

  //// private

  private onLocalIceCandidate (e: RTCPeerConnectionIceEvent) {
    this.emit(PeerEvent.LocalIceCandidate, e.candidate);
  }

  private onTrack (e: RTCTrackEvent) {
    if (!this.addedStream) {
      this.addedStream = true;

      this.emit(PeerEvent.StreamAvailable, e.streams[0]);
      this.startIdleCheck();
    }
  }

  private startIdleCheck () {
    const checkIdle = async () => {

      if (this.closed) {
        return;
      } else if (this.connection.connectionState === 'connected') {
        const packetsReceived = await this.getPacketsReceived();
        const now = +new Date();
        if (packetsReceived > this.packetsReceived) {
          this.packetsLastReceivedAt = now;
          this.packetsReceived = packetsReceived;

          if (this.timedOut) {
            this.emit(PeerEvent.TimeoutRecovered);
            this.timedOut = false;
          }
        } else if (this.packetsReceived > 0) {
          const age = now - this.packetsLastReceivedAt;
          if (age > this.streamDataTimeoutSeconds * 1000) {
            if (!this.timedOut) {
              this.emit(PeerEvent.Timeout);
              this.timedOut = true;
            }
          }
        }
        setTimeout(checkIdle, 100);
      } else if (!this.packetsReceived) {
        setTimeout(checkIdle, 100);
      }
    };
    checkIdle();
  }

  private onConnectionStateChange (e: Event) {
    if (this.connection.connectionState === 'failed' ||
      this.connection.connectionState === 'closed')
    {
      this.close();
    }
  }

  private onSignalingStateChange (e: Event) {
    if (this.connection.signalingState === 'stable') {
      this.emit(PeerEvent.IceTrickleComplete, e);
      this.connection.onsignalingstatechange = () => {};
    }
  }

  private onNegotiationNeeded (e: Event) {
  }

  private async getPacketsReceived () {
    return this.connection.getStats().then((stats) => {
      if (!stats) return 0;

      let total = 0;

      stats.forEach((stat) => {
        if (stat.type === 'inbound-rtp') {
          total += stat.packetsReceived;
        }
      });

      return total;
    });
  }
}