import { Emitter } from '../../emitter';
import { PublisherOptions } from '../publisher-options';
import { SocketEvent } from './events';
import * as SocketMessage from './messages';
import {
  MessageDestination,
  DataObjectUpdateOperation
} from '../data';

export { SocketEvent, SocketMessage };

type MessageHandler = (...args: [SocketMessage.Message]) => void;

interface MessageHandlers {
  [key: string]: MessageHandler,
}

export interface SocketOptions {
  host: string,
  port: number,
  protocol: string,
  shortName: string,
  rendition: string,
};

export class Socket extends Emitter {

  private ws: WebSocket;
  public closed = true;

  private messageHandlers: MessageHandlers = {
    'init': this.onInitMessage,
    'authenticated': this.onAuthenticatedMessage,
    'pong': this.onPongMessage,
    'ingest-started': this.onIngestStartedMessage,
    'ingest-stopped': this.onIngestStoppedMessage,
    'sdp.offer-response': this.onOfferResponseMessage,
    'ice.candidate': this.onRemoteIceCandidateMessage,
    'dataobject.broadcast': this.onDataObjectBroadcastMessage,
    'dataobject.message': this.onDataObjectReceiveMessage,
    'dataobject.update-response': this.onDataObjectUpdateResponseMessage,
    'on-fi': this.onFIMessage,
  }

  constructor () {
    super();
  }

  public async connect (options: SocketOptions) {
    if (!this.closed) {
      await this.close();
    } 

    const url = makeURL(options);
    try  {
      this.ws = new WebSocket(url);
    } catch (e) {
      this.emit(SocketEvent.Error, e);
    }

    this.closed = false;
    this.ws.onerror = this.onError.bind(this);
    this.ws.onmessage = this.onMessage.bind(this);
    this.ws.onclose = this.onClose.bind(this);
  }

  private onError (error: Error) {
    this.emit(SocketEvent.Error, error);
  }

  private onClose (event: CloseEvent) {
    this.closed = true;
    this.ws = null;
    this.emit(SocketEvent.Close, event);
  }

  private onMessage (event: MessageEvent) {
    const data = JSON.parse(event.data);
    const handler: any = this.messageHandlers[data.type];
    handler.bind(this)(data);
  }

  private onInitMessage (message: SocketMessage.InitMessage) {
    this.emit(SocketEvent.Init, message);
  }

  private onAuthenticatedMessage (message: SocketMessage.AuthenticatedMessage) {
    this.emit(SocketEvent.Authenticated, message);
  }

  private onPongMessage (message: SocketMessage.PongMessage) {  }

  private onIngestStartedMessage (message: SocketMessage.IngestStartedMessage) {
    this.emit(SocketEvent.IngestStarted, message);
  }

  private onIngestStoppedMessage (message: SocketMessage.IngestStoppedMessage) {
    this.emit(SocketEvent.IngestStopped, message);
  }
  
  private onOfferResponseMessage (message: SocketMessage.OfferResponseMessage) {
    this.emit(SocketEvent.OfferResponse, message);
  }

  private onRemoteIceCandidateMessage (message: SocketMessage.RemoteIceCandidateMessage) {
    this.emit(SocketEvent.RemoteIceCandidate, message);
  }

  private onDataObjectBroadcastMessage (message: SocketMessage.DataObjectBroadcastMessage) {
    this.emit(SocketEvent.DataObjectBroadcast, message);
  }

  private onDataObjectReceiveMessage (message: SocketMessage.DataObjectReceiveMessage) {
    this.emit(SocketEvent.DataObjectReceive, message);
  }

  private onDataObjectUpdateResponseMessage (message: SocketMessage.DataObjectUpdateResponseMessage) {
    this.emit(SocketEvent.DataObjectUpdateResponse, message);
  }

  private onFIMessage (message: SocketMessage.OnFIMessage) {
    this.emit(SocketEvent.OnFI, message);
  }

  private send (data: any) {
    if (this.closed) {
      throw new Error('Socket closed, cannot send ' + JSON.stringify(data));
    } else {
      this.ws.send(JSON.stringify(data));
    }
  }

  public async authenticate (username: string, password: string) {
    this.send({
      type: 'authenticate',
      username,
      password,
      protocol: 'webrtc',
    });
  }

  public startPing (seconds: number) {
    const ping = () => {
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        this.send({
          type: 'ping',
        });
        setTimeout(ping, seconds * 1000);
      }
    };

    ping();
  }

  public startIngest () { // streamName: string, bitrate: number) {
    this.send({
      type: 'start-ingest',
    });
  }

  public stopIngest () {
    return new Promise((resolve, reject) => {
      this.one(SocketEvent.IngestStopped, resolve);
      this.send({
        type: 'stop-ingest',
      });
    });
  }

  public handleOffer (offer: string) {
    this.send({
      type: 'sdp.offer',
      offer,
    });
  }

  public handleLocalIceCandidate (candidate: RTCIceCandidate) {
    if (candidate) {
      this.send({
        type: 'ice.candidate',
        candidate: candidate.candidate,
        index: candidate.sdpMLineIndex,
      });
    } else {
      this.send({
        type: 'ice.done',
      });
    }
  }

  public async close () {
    return new Promise((resolve, reject) => {
      this.on(SocketEvent.Close, resolve);
      setTimeout(reject, 10000);
      this.ws.close();
    });
  }

  public sendMessage(destination: MessageDestination, msg: string) {
    this.send({
      "type": "dataobject.send-message",
      "destination": destination,
      "msg": msg
    });
  }

  public sendUpdate(operation: DataObjectUpdateOperation, requestResponseCorrelationId: string) {
    this.send({
      "type": "dataobject.update",
      "requestResponseCorrelationId": requestResponseCorrelationId,
      "operation": operation
    })
  }
}

function makeURL ({
  host,
  port,
  protocol,
  shortName,
  rendition
}: SocketOptions) {
  return protocol +
    '://' +
    host +
    ':' +
    port +
    '/public/ingest/' +
    shortName +
    '/' +
    rendition +
    '/session';
}