/** Options dictionary for Subscriber initialization */

export class SubscriberOptions {
  /** Media element to display the media stream */
  public mediaElement?: HTMLMediaElement;

  /** id of the subscriber media element. May also be specified with mediaElement */
  public mediaElementId?: string;

  /** Stream discovery service hostname */
  public host?: string;

  /** Stream discovery HTTP or HTTPS port */
  public port?: number = 443;

  /** Short name */
  public shortName?: string;

  /** Stream name */
  public streamName?: string;

  /** Protocol for discovery service connection (http or https) */
  public protocol?: string = 'https';

  /** Encoded URL to playback streams protected by Media Vault */
  public encodedURL?: string = '';

  /** Whether the SDK should attempt to autoplay the video on subscribe */
  public autoplay?: boolean;

  /** Whether to initially mute the video element, which may allow autoplay on some platforms */
  public muted?: boolean;

  /** Interval to wait between sending periodic server pings */
  public pingIntervalSeconds? = 15;

  /** Number of seconds to wait for playback to start before triggering a subscription failure */
  public subscribeTimeoutSeconds? = 10;

  /** Number of seconds to wait without receiving any stream data after subscription before triggering failover */
  public streamDataTimeoutSeconds? = 5;

  /** Whether to automatically retry the most recent WebSocket endpoint after an initial failure, instead of attempting to discover an alternative host */
  public retrySocketOnFirstFailure? = true;

  /** Maximum number of times the SDK will attempt to automatically recover from subscription or playback failures */
  public maxFailoverAttempts? = Infinity;

  /** Configuration object used to configure the RTCPeerConnection */
  public rtcConfiguration?: RTCConfiguration = {
    iceServers: [ {urls: 'stun:stun2.1.google.com:19302'} ],
    iceTransportPolicy: 'all',
    iceCandidatePoolSize: 2,
  };
}
