export function setMediaBitrate(sdp: string, media: string, bitrate: number) {
  var lines = sdp.split("\n");
  var line = -1;
  for (var i = 0; i < lines.length; i++) {
    if (lines[i].indexOf("m="+media) === 0) {
      line = i;
      break;
    }
  }
  if (line === -1) {
    return sdp;
  }

  // Pass the m line
  line++;

  // Skip i and c lines
  while(lines[line].indexOf("i=") === 0 || lines[line].indexOf("c=") === 0) {
    line++;
  }

  var newLines
  // If we're on a b line, replace it
  if (lines[line].indexOf("b") === 0) {
    lines[line] = "b=AS:"+bitrate;
    newLines = lines.slice(0, line);
  }
  else {
    // Add a new b line
    newLines = lines.slice(0, line);
    newLines.push("b=AS:"+bitrate);
  }

  newLines.push("a=fmtp:102 x-google-start-bitrate=" + bitrate + "; x-google-max-bitrate=" + Math.trunc(bitrate * 1.1) + "; x-google-min-bitrate=" + Math.trunc(bitrate * 0.75));
  newLines = newLines.concat(lines.slice(line, lines.length));

  return newLines.join("\n");
}