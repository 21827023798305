export interface EmitterMessage<T> {
  type: string,
  data: T,
}

export class Emitter {

  private eventHandlers: { [key: string]: (() => void)[] } = {};

  /**
   * Bind a callback function to trigger each time the specified event fires
   *
   * @param eventName String event identifier to listen for
   * @param cb Function to call each time the event fires
   */
  public on (eventName: any, cb: any): void {
    const key = String(eventName);
    if (!this.eventHandlers[String(key)]) {
      this.eventHandlers[String(key)] = [];
    }
    this.eventHandlers[key].push(cb);
  }

  /**
   * Bind a callback function to trigger only the next time the event fires
   *
   * @param eventName String event identifier to listen for
   * @param cb Function to call the next time the event fires
   */
  public one (eventName: any, cb: any): void {
    this.on(eventName, cb);
    this.on(eventName, () => {
      this.off(eventName, cb);
    });
  }

  /**
   * Unbind an event callback function
   *
   * @param eventName String event identifier to listen for
   * @param cb Previously bound function to unbind. If not provided, all previously bound functions will be removed for this event
   */
  public off (eventName: any, cb?: any): void {
    const key = String(eventName);

    if (cb === undefined) {
      delete this.eventHandlers[key];
    } else if (this.eventHandlers[key]) {
      for (let i=0; i<this.eventHandlers[key].length; i++) {
        if (this.eventHandlers[key][i] === cb) {
          this.eventHandlers[key].splice(i, 1);
        }
      }
    }
  }

  protected emit (eventName: any, data?: any) {
    const type = String(eventName);
    const payload = { type, data };

    //if (eventName !== 'Subscribe.Time.Update') {  console.log('emit ' + eventName, data); }

    if (this.eventHandlers[type]) {
      this.eventHandlers[type].forEach((handler) => {
        handler.call(this, payload);
      });
    }

    if (this.eventHandlers.hasOwnProperty('*')) {
      const wildcardHandlers = this.eventHandlers['*'];
      for (var i=0; i<wildcardHandlers.length; i++) {
        wildcardHandlers[i].call(this, payload);
      }
    }
  }
}
