import * as md5 from 'md5';

var validationURLTemplate = 'https://{host}/{shortName}/auth/v2/{streamName}/';

/**
 * Generate a validation URL for playing back streams protected by Media Vault
 */
export function buildValidationURL (
{
  host = 'subscribe-validator.rts.llnwi.net',
  shortName,
  streamName,
  secret,
  params = '',
}: {
  /** Media Vault validation server hostname */
  host?: string;

  /** Customer Short Name */
  shortName: string;

  /** Name of the media stream to validate */
  streamName: string;

  /** Media Vault pre-shared secret */
  secret: string;

  /** Additional query params to include in the resulting validation URL */
  params?: string;
}): string {

  var validationURL = validationURLTemplate
      .replace('{host}', host)
      .replace('{shortName}', shortName)
      .replace('{streamName}', streamName);

  var hashSource = secret + validationURL + (params.length ? '?' + params : '');
  var hash = md5(hashSource);

  if (params) {
    return validationURL + '?' + params + '&h=' + hash;
  } else {
    return validationURL + '?h=' + hash;
  }
};
