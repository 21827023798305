/** Event identifiers emitted by RTS Publishers and Subscribers */
export enum RTSEvent {
  /** Peer Connection received a remote ICE candidate */
  WEBRTC_CANDIDATE_REMOTE = 'WebRTC.Candidate.Remote',

  /** WebRTC answer received from the remote server */
  WEBRTC_ANSWER = 'WebRTC.Answer',

  /** WebRTC ffer received from the remote server */
  WEBRTC_OFFER = 'WebRTC.Offer',

  /** New local ICE candidate available */
  WEBRTC_CANDIDATE_LOCAL = 'WebRTC.Candidate.Local',

  /** MediaStream has been added to the peer connection */
  WEBRTC_ADD_STREAM = 'WebRTC.Add.Stream',

  /** ICE trickle complete */
  WEBRTC_ICETRICKLE_COMPLETE = 'WebRTC.IceTrickle.Complete',

  /** WebRTC peer connection available */
  WEBRTC_PEERCONNECTION_AVAILABLE = 'WebRTC.PeerConnection.Available',

  /** Subscription failed because the stream name was invalid */
  SUBSCRIBE_INVALIDNAME = 'Subscribe.InvalidName',

  /** Subscription attempt failed */
  SUBSCRIBE_FAIL = 'Subscribe.Fail',

  /** Subscription stopped */
  SUBSCRIBE_STOP = 'Subscribe.Stop',

  /** Subscription started successfully */
  SUBSCRIBE_START = 'Subscribe.Start',

  /** Media element autoplay attempt was blocked by the browser */
  SUBSCRIBE_AUTOPLAY_FAIL = 'Subscribe.Autoplay.Fail',

  /** Emitted continuously during playback as the current time advances */
  SUBSCRIBE_TIME_UPDATE = 'Subscribe.Time.Update',

  /** Playback quality changed to a new profile */
  SUBSCRIBE_QUALITY_CHANGE = 'Subscribe.Quality.Change',

  /** Media element fullscreen mode toggled */
  SUBSCRIBE_FULLSCREEN_CHANGE = 'Subscribe.Fullscreen.Change',

  /** Media element volume was changed */
  SUBSCRIBE_VOLUME_CHANGE = 'Subscribe.Volume.Change',

  /** Media element playback started, paused, or resumed */
  SUBSCRIBE_PLAYBACK_CHANGE = 'Subscribe.Playback.Change',

  /** Provides a list of currently available quality profile levels */
  SUBSCRIBE_ACTIVE_PROFILES = 'Subscribe.ActiveProfiles',

  /** Provides a list of quality profiles available from the alternate stream source (primary or backup) */
  SUBSCRIBE_PEER_ACTIVE_PROFILES = 'Subscribe.PeerActiveProfiles',

  /** Emitted when an alternate stream source becomes available */
  SUBSCRIBE_PEER_STARTED = 'Subscribe.PeerStarted',


  /** Emitted when an alternate stream source is no longer available */
  SUBSCRIBE_PEER_STOPPED = 'Subscribe.PeerStopped',

  /** Provides the stream time zero offset */
  SUBSCRIBE_TIME_ZERO = 'Subscribe.Time.Zero',

  /** Current shared data object details */
  SUBSCRIBE_DATAOBJECT_BROADCAST = 'Subscribe.DataObject.Broadcast',

  /** Emitted when a remote message is received from another connected client */
  SUBSCRIBE_DATAOBJECT_MESSAGE = 'Subscribe.DataObject.Message',

  /** Emitted in response to a successful data object update request */
  SUBSCRIBE_DATAOBJECT_UPDATE_RESPONSE = 'Subscribe.DataObject.UpdateResponse',

  /** Emitted in response to a failure to transmit a message */
  SUBSCRIBE_DATAOBJECT_MESSAGE_FAILURE = 'Subscribe.DataObject.MessageFailure',

  /** Emitted when publishing starts successfully */
  PUBLISH_START = 'Publish.Start', 

  /** Emitted when an error occurs publishing a stream */
  PUBLISH_FAIL = 'Publish.Fail', 

  /** Attempt to publish using an invalid stream name */
  PUBLISH_INVALIDNAME = 'Publish.InvalidName', 

  /** Successfully halted stream publishing */
  UNPUBLISH_SUCCESS = 'Unpublish.Success', 

  /** Publish metadata is available */
  PUBLISH_METADATA = 'Publish.Metadata', 

  /** Emitted when dimensions of the published stream change */
  DIMENSION_CHANGE = 'Publisher.Video.DimensionChange', 

  /** Emitted after the discovery service directs the client to an alternate server */
  SUBSCRIBE_CONNECT_REDIRECT = 'Subscribe.Connect.Redirect',

  /** Emitted when the subscriber connection is closed */
  SUBSCRIBE_CONNECTION_CLOSED = 'Subscribe.Connection.Closed',

  /** Publisher connection closed */
  PUBLISHER_CONNECTION_CLOSED = 'Publisher.Connection.Closed',

  /** Failure to connect to remote server */
  CONNECT_FAILURE = 'Connect.Failure',

  /** Successfully connected to remote server */
  CONNECT_SUCCESS = 'Connect.Success',
}
