import { Emitter } from '../emitter';
import * as SocketMessage from './messages';

export enum SocketEvent {
  Init = 'Init',
  Bye = 'Bye',
  RemoteIceCandidate = 'RemoteIceCandidate',
  IceTrickleComplete = 'IceTrickleComplete',
  OfferResponse = 'OfferResponse',
  StreamAvailable = 'StreamAvailable',
  QualityChange = 'QualityChange',
  ValidationCookie = 'ValidationCookie',
  ActiveProfiles = 'ActiveProfiles',
  PeerActiveProfiles = 'PeerActiveProfiles',
  PeerStarted = 'PeerStarted',
  PeerStopped = 'PeerStopped',
  DataObjectBroadcast = 'DataObjectBroadcast',
  DataObjectReceive = 'DataObjectReceive',
  DataObjectUpdateResponse = 'DataObjectUpdateResponse',
  DataObjectMessageFailure = 'DataObjectMessageFailure',
  TimeZero = 'TimeZero',
  Close = 'Close',
  Error = 'Error',
  OnFI = 'OnFI',
}

export { SocketMessage };

const closeTimeoutSeconds = 10;

type MessageHandler = (...args: [SocketMessage.Message]) => void;

interface MessageHandlers {
  [key: string]: MessageHandler,
}

export class Socket extends Emitter {
  private ws: WebSocket;
  private activeVariant: string;
  private activeRole: string;
  private closing: Promise<void>;
  private validationCookie: string;
  
  constructor () {
    super();
  }

  public async connect (url: string) {
    if (this.closing) {
      await this.closing;
    }

    if (this.ws) {
      await this.close();
    }
    this.ws = new WebSocket(url);
    this.ws.onerror = this.onError.bind(this);
    this.ws.onmessage = this.onMessage.bind(this);
    this.ws.onclose = this.onClose.bind(this);
  }

  public send (data: Object) {
    this.ws.send(JSON.stringify(data));
  }

  public async close () {
    if (this.closing) {
      await this.closing;
    }

    this.closing = new Promise((resolve: () => void, reject: () => void) => {
      if (!this.ws) {
        resolve();
      } else {
        this.ws.onerror = () => {};
        this.ws.onclose = () => {};
        this.ws.onmessage = () => {};

        if (this.ws.readyState !== WebSocket.CLOSING &&
          this.ws.readyState !== WebSocket.CLOSED)
        {
          this.ws.close();
        }

        let interval: ReturnType<typeof setInterval>, timeout: ReturnType<typeof setTimeout>;
        interval = setInterval(() => {
          if (this.ws.readyState === WebSocket.CLOSED) {
            clearInterval(interval);
            clearTimeout(timeout);
            this.ws = undefined;
            resolve();
          }
        }, 5);

        timeout = setTimeout(() => {
          console.error('Subscriber Socket timed out waiting to close');
          clearInterval(interval);
          reject();
        }, closeTimeoutSeconds * 1000);
      }
    });

    return this.closing;
  }

  public getRole () {
    return this.activeRole;
  }

  public setRole (roleName: string) {
    this.send({
      type: 'switch-slot-role',
      role: roleName,
    });
  }

  public getVariant () {
    return this.activeVariant;
  }

  public setVariant (profileName: string) {
    this.send({
      type: 'set-quality',
      variant: profileName,
    });
  }

  public forceVariant (profileName: string) {
    this.send({
      type: 'set-quality-constraint-configuration',
      configuration: {
        behavior: 'force-quality',
        variant: profileName,
      },
    });
  }

  public autoMaxVariant (variant?: string) {
    this.send({
      type: 'set-quality-constraint-configuration',
      configuration: {
        behavior: 'max-quality',
        variant: variant || null,
      },
    });
  }

  public handleOffer (offer: string) {
    this.send({
      type: 'sdp.offer',
      offer,
    });
  }

  public handleLocalIceCandidate (candidate: RTCIceCandidate) {
    if (candidate) {
      this.send({
        type: 'ice.candidate',
        candidate: candidate.candidate,
        index: candidate.sdpMLineIndex,
      });
    } else {
      this.send({
        type: 'ice.done',
      });
    }
  }

  public startPing (seconds: number) {
    const ping = () => {
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        this.send({
          type: 'ping',
          validationCookie: this.validationCookie || undefined,
        });
        setTimeout(ping, seconds * 1000);
      }
    };

    ping();
  }

  //// private

  private onError (error: Error) {
    this.emit(SocketEvent.Error, error);
  }

  private onClose (event: CloseEvent) {
    this.emit(SocketEvent.Close, event);
  }

  private messageHandlers: MessageHandlers = {
    'init': this.onInitMessage,
    'bye': this.onByeMessage,
    'sdp.offer-response': this.onOfferResponseMessage,
    'ice.candidate': this.onRemoteIceCandidateMessage,
    'quality-change': this.onQualityChangeMessage,
    'active-profiles': this.onActiveProfilesMessage,
    'peer-active-profiles': this.onPeerActiveProfilesMessage,
    'peer-started': this.onPeerStartedMessage,
    'peer-stopped': this.onPeerStoppedMessage,
    'time-zero': this.onTimeZeroMessage,
    'dataobject.broadcast': this.onDataObjectBroadcastMessage,
    'dataobject.message': this.onDataObjectReceiveMessage,
    'dataobject.update-response': this.onDataObjectUpdateResponseMessage,
    'dataobject.message-failure': this.onDataObjectUpdateMessageFailureMessage,
    'pong': this.onPongMessage,
    'on-fi': this.onFIMessage,
  };

  private onMessage (event: MessageEvent) {
    const data = JSON.parse(event.data);
    const handler: any = this.messageHandlers[data.type];
    handler.bind(this)(data);
  }

  private onInitMessage (message: SocketMessage.InitMessage) {
    this.validationCookie = message.validationCookie;
    this.emit(SocketEvent.Init, message);
    this.emit(SocketEvent.ValidationCookie, this.validationCookie);
  }

  private onByeMessage (message: SocketMessage.ByeMessage) {
    this.emit(SocketEvent.Bye, message);
  }

  private onQualityChangeMessage (message: SocketMessage.QualityChangeMessage) {
    this.activeVariant = message.activeVariant;
    this.activeRole = message.activeRole;
    this.emit(SocketEvent.QualityChange, message);
  }

  private onRemoteIceCandidateMessage (message: SocketMessage.RemoteIceCandidateMessage) {
    this.emit(SocketEvent.RemoteIceCandidate, message);
  }

  private onOfferResponseMessage (message: SocketMessage.OfferResponseMessage) {
    this.emit(SocketEvent.OfferResponse, message);
  }

  private onActiveProfilesMessage (message: SocketMessage.ActiveProfilesMessage) {
    this.emit(SocketEvent.ActiveProfiles, message);
  }

  private onPeerActiveProfilesMessage (message: SocketMessage.PeerActiveProfilesMessage) {
    this.emit(SocketEvent.PeerActiveProfiles, message);
  }

  private onPeerStartedMessage (message: SocketMessage.PeerStartedMessage) {
    this.emit(SocketEvent.PeerStarted, message);
  }

  private onPeerStoppedMessage (message: SocketMessage.PeerStoppedMessage) {
    this.emit(SocketEvent.PeerStopped, message);
  }

  private onTimeZeroMessage (message: SocketMessage.TimeZeroMessage) {
    this.emit(SocketEvent.TimeZero, message);
  }

  private onDataObjectReceiveMessage (message: SocketMessage.DataObjectReceiveMessage) {
    this.emit(SocketEvent.DataObjectReceive, message);
  }

  private onDataObjectBroadcastMessage (message: SocketMessage.DataObjectBroadcastMessage) {
    this.emit(SocketEvent.DataObjectBroadcast, message);
  }

  private onDataObjectUpdateResponseMessage (message: SocketMessage.DataObjectUpdateResponseMessage) {
    this.emit(SocketEvent.DataObjectUpdateResponse, message);
  }

  private onDataObjectUpdateMessageFailureMessage (message: SocketMessage.DataObjectMessageFailureMessage) {
    this.emit(SocketEvent.DataObjectMessageFailure, message);
  }

  private onPongMessage (message: SocketMessage.PongMessage) {
    if (message.validationCookie) {
      this.validationCookie = message.validationCookie;
      this.emit(SocketEvent.ValidationCookie, this.validationCookie);
    }
  }

  private onFIMessage (message: SocketMessage.OnFIMessage) {
    this.emit(SocketEvent.OnFI, message);
  }

}