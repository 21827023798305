import { Emitter } from '../../emitter';
import { PeerEvent } from './events';

export { PeerEvent };

export class Peer extends Emitter {

  private connection: RTCPeerConnection;
  private closed = true;

  constructor () {
    super();
  }

  public init (config: RTCConfiguration, stream: MediaStream) {
    if (!this.closed) {
      this.close();
    }

    this.connection = new RTCPeerConnection(config);
    this.closed = false;
    this.connection.onconnectionstatechange = this.onConnectionStateChange.bind(this);
    this.connection.onsignalingstatechange = this.onSignalingStateChange.bind(this);
    this.connection.onicecandidate = this.onLocalIceCandidate.bind(this);
    const tracks = stream.getTracks();
    tracks.forEach((track) => {
      this.connection.addTrack(track)
    });
  }

  private onConnectionStateChange (e: Event) {
    if (this.connection.connectionState === 'failed' ||
      this.connection.connectionState === 'closed')
    {
      this.close();
    }
  }

  private onSignalingStateChange (e: Event) {
    if (this.connection.signalingState === 'stable') {
      this.emit(PeerEvent.IceTrickleComplete, e);
      this.connection.onsignalingstatechange = () => {};
    }
  }

  private onLocalIceCandidate (e: RTCPeerConnectionIceEvent) {
    this.emit(PeerEvent.LocalIceCandidate, e.candidate);
  }

  public async createOffer (sdpTransform: (arg0: string) => string) {
    return this.connection.createOffer({
      iceRestart: false,
      offerToReceiveAudio: false,
      offerToReceiveVideo: false,
    }).then(async (description) => {

      description.sdp = sdpTransform(description.sdp);

      await this.connection.setLocalDescription(description);
      return this.connection.localDescription.sdp;
    });
  }

  public getConnection () {
    return this.connection;
  }

  public async handleOfferResponse (description: RTCSessionDescription) {
    return this.connection.setRemoteDescription(description);
  }

  public async handleRemoteIceCandidate (candidate: RTCIceCandidate) {
    return this.connection.addIceCandidate(candidate);
  }

  public close () {
    if (!this.closed) {
      this.connection.onconnectionstatechange = () => {};
      this.connection.onsignalingstatechange = () => {};
      this.connection.ontrack = () => {};
      this.connection.onicecandidate = () => {};
      this.connection.close();
      this.connection = undefined;
      this.closed = true;
      this.emit(PeerEvent.Closed);
    }
  }

}
