export enum SocketEvent {
  Init,
  Close,
  Error,
  Authenticated,
  IngestStarted,
  IngestStopped,
  RemoteIceCandidate,
  IceTrickleComplete, // TODO
  OfferResponse,
  DataObjectBroadcast,
  DataObjectReceive,
  DataObjectUpdateResponse,
  OnFI,
}