import { Publisher, PublisherOptions } from './publisher';
import { Subscriber, SubscriberData, SubscriberOptions } from './subscriber';
import { buildValidationURL } from './build-validation-url';
import { RTSEvent } from './events';

/** Current SDK version */
const version = '2.0.2.2021.2.11';

/** Exposed as window.llnwrtssdk */
export {
  version,
  buildValidationURL,
  RTSEvent,
  Publisher,
  PublisherOptions,
  Subscriber,
  SubscriberData,
  SubscriberOptions,
};
