import { Emitter, EmitterMessage } from '../emitter';
import { Discovery } from './discovery';
import { Socket, SocketEvent, SocketMessage } from './socket';
import { Peer, PeerEvent } from './peer';
import { SubscriberOptions } from './options';
import { SubscriberData } from './data';
import { RTSEvent } from '../events';

export {
  SubscriberData,
  SubscriberOptions,
};

enum SubscriberState {
  Unsubscribed,
  Connecting,
  Recovering,
  Subscribed,
}


/** Playback states provided for legacy compatibility with v1 */
export enum RTSState {
  PLAYBACK_PAUSED = 'Playback.Paused',
  PLAYBACK_PLAYING = 'Playback.Playing',
}


function encodeParams (url: string): string {
  return url.replace(/\?/g, '%3F').replace(/&/g, '%26');
}

function decodeParams (encodedURL: string): string {
  return encodedURL.replace(/%3F/gi, '?').replace(/%26/g, '&');
}

/** Establishes stream connection and manage playback */
export class Subscriber extends Emitter {

  private subscribing: Promise<Subscriber> = new Promise((resolve: (arg0: Subscriber) => void) => resolve(undefined));
  private rejectSubscribe: ((arg0: Error) => void) = () => {};
  private resolveSubscribe: ((arg0: Subscriber) => void) = (s) => {};
  private subscribeTimeout: ReturnType<typeof setTimeout>;
  private roleTimeout: ReturnType<typeof setTimeout>;

  private discovery: Discovery;
  private socket: Socket = new Socket();
  private peer: Peer = new Peer();

  private el: HTMLMediaElement;
  private state: SubscriberState = SubscriberState.Unsubscribed;
  private muted: boolean = false;
  private preMuteVolume: number;
  private validationCookie: string;
  private failoverAttempts = 0;
  private streamName: string;

  /** Subscriber data object command module */
  public data: SubscriberData = new SubscriberData();

  /**
   * Passing options to the constructor triggers initialization automatically
   *
   * @param options Subscriber configuration parameters
   */
  constructor (private options?: SubscriberOptions) {
    super();

    this.setupSocket();
    this.data.setSocket(this.socket);

    this.setupPeer();

    if (options) {
      this.init(options);
    }
  }

  /**
   * Provide initial configuration options and prepare for subscription
   *
   * @param options Subscriber configuration parameters
   * */
  public async init (options: SubscriberOptions) {
    this.options = { ...new SubscriberOptions(), ...options };

    this.discovery = new Discovery({
      protocol: this.options.protocol,
      host: this.options.host,
      port: this.options.port,
      shortName: this.options.shortName,
      retrySocketOnFirstFailure: this.options.retrySocketOnFirstFailure,
    });

    if (this.el) this.unbindVideoHandlers();

    if (this.options.mediaElement) {
      this.el = this.options.mediaElement;
    } else {
      this.el = document.getElementById(this.options.mediaElementId) as HTMLMediaElement ;
    }

    if (!this.el) {
      throw 'Missing media element';
    }

    this.bindVideoHandlers();

    if (this.options.autoplay !== undefined) this.el.autoplay = this.options.autoplay;

    if (this.options.muted === undefined) {
      this.options.muted = this.el.muted;
    } else {
      this.el.muted = this.options.muted;
      this.muted = this.options.muted;
    }

    return this;
  }

  /**
   * Establish a new subscription and begin streaming media content
   *
   * @param streamName If provided, override the stream name parameter provided during initialization
   * */
  public async subscribe (streamName?: string) {
    if (this.state !== SubscriberState.Recovering
      && this.state !== SubscriberState.Unsubscribed)
    {
      await this.unsubscribe();
    }

    if (!streamName) {
      streamName = this.options.streamName;
    }
    if (streamName !== this.streamName) {
      this.validationCookie = undefined;
    }
    this.streamName = streamName;

    let url: string;
    try {
      url = await this.discovery.getBestEndpointUrl(this.streamName);
    } catch (error) {
      this.state = SubscriberState.Unsubscribed;
      await this.close();
      this.emit(RTSEvent.SUBSCRIBE_FAIL, error);
      return Promise.reject(error);
    }

    await this.socket.connect(this.addSocketParams(url));

    this.subscribing = new Promise((resolve: (arg0: Subscriber) => void, reject: (arg0: Error) => void) => {
      this.resolveSubscribe = (subscriber: Subscriber) => {
        clearTimeout(this.subscribeTimeout);
        resolve(subscriber);
      };

      this.rejectSubscribe = async (error: Error) => {
        clearTimeout(this.subscribeTimeout);
        this.state = SubscriberState.Unsubscribed;
        await this.close();
        reject(error);
      }

      this.subscribeTimeout = setTimeout(() => {
        this.failover().catch(async (error) => {
          this.state = SubscriberState.Unsubscribed;
          await this.close();
          this.emit(RTSEvent.SUBSCRIBE_FAIL);
          reject(error);
        });
      }, this.options.subscribeTimeoutSeconds * 1000);
    });

    this.subscribing.catch(async (error) => {
      clearTimeout(this.subscribeTimeout);
      this.state = SubscriberState.Unsubscribed;
      await this.close();
      this.emit(RTSEvent.SUBSCRIBE_FAIL, error);
    });

    return this.subscribing;
  }

  /** Request re-establishment of the current subscription */
  public async failover () {
    if (this.state === SubscriberState.Subscribed) {
      if (this.failoverAttempts >= this.options.maxFailoverAttempts) {
        return this.unsubscribe();
      } else {
        this.failoverAttempts++;
        this.state = SubscriberState.Recovering;
        this.discovery.failEndpoint();
        await this.close();
        return this.subscribe().catch((e) => {
          this.state = SubscriberState.Unsubscribed;
          this.close();
          this.emit(RTSEvent.SUBSCRIBE_FAIL, e);
        });
      }
    }
  }

  /** Return the currently active playback quality variant */
  public getVariant () {
    return this.socket.getVariant();
  }

  /**
   * Request playback quality auto-selection based on available bandwidth
   *
   * @param variant Maximum quality variant for auto-selection
   */
  public autoMaxVariant (variant?: string) {
    return this.socket.autoMaxVariant(variant);
  }

  /**
   * Request a specific quality variant
   *
   * @param profileName Quality variant profile name
  */
  public setVariant (profileName: string) {
    return this.socket.setVariant(profileName);
  }

  /**
   * Force selection of a specific quality variant and suspend automatic variant switching
   *
   * @param profileName Quality variant profile name
   */
  public forceVariant (profileName: string) {
    return this.socket.forceVariant(profileName);
  }

  /** Return the current role (primary or backup) */
  public getRole () {
    return this.socket.getRole();
  }

  /**
   * Request switch to another role
   *
   * @param roleName Name of the role to switch to (primary or backup)
  */
  public setRole (roleName: string) {
    return this.socket.setRole(roleName);
  }

  /** Terminate the current subscription and close all connections */
  public async unsubscribe () {
    if (this.state != SubscriberState.Unsubscribed) {
      this.state = SubscriberState.Unsubscribed;
      await this.close();
      this.emit(RTSEvent.SUBSCRIBE_STOP);
      //this.rejectSubscribe(new Error('Unsubscribed'));
    }
  }

  /** Begin or resume media playback */
  public async play () {
    return this.el.play();
  }

  /** Pause media playback */
  public async pause () {
    return this.el.pause();
  }

  /** Request toggling the video player's fullscreen mode */
  public async toggleFullScreen () {
    if (document.fullscreenElement) {
      return document.exitFullscreen();
    } else {
      const el = this.el as any;
      const rfs = el.requestFullscreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen;
      rfs.call(this.el);
    }
  }

  /**
   * Set audio playback volume
   *
   * @param volume Value between 0 (quietest) and 1 (loudest)
   */
  public setVolume (value: number) {
    if (!this.muted) {
      this.el.volume = value;
    }
  }

  /** Mute playback audio */
  public mute () {
    this.muted = true;
    this.preMuteVolume = this.el.volume;
    if (this.preMuteVolume === 0) {
      this.emit(RTSEvent.SUBSCRIBE_VOLUME_CHANGE, { volume: 0, muted: true, fillin: true });
    } else {
      this.el.volume = 0;
    }
  }

  /** Set playback volume to the pre-mute level */
  public unmute () {
    if (this.muted) {
      if (this.preMuteVolume === 0) {
        this.emit(RTSEvent.SUBSCRIBE_VOLUME_CHANGE, { volume: 0, muted: false });
      } else {
        this.el.volume = this.preMuteVolume || 0.5;
      }
      this.muted = false;
      this.el.muted = false;
    }
  }

  /** Indicated whether the playback audio is currently muted */
  public getMuted () {
    return this.muted;
  }

  /** Retrieve the media stream from the peer connection */
  public getMediaStream () {
    return this.el.srcObject as MediaStream;
  }

  /** Retrieve the peer connection object */
  public getPeerConnection () {
    return this.peer.getConnection();
  }

  /** Get the number of currently available endpoints based on the most recent discovery request */
  public endpointCount () {
    return this.discovery.endpointCount();
  }


  // private

  private async close () {
    clearTimeout(this.subscribeTimeout);

    if (this.peer) {
      this.peer.close();
    }
    if (this.socket) {
      await this.socket.close();
    }

    this.el.srcObject = null;
  }

  private addSocketParams (socketURL: string): string {
    const params = [];

    if (this.options.encodedURL) {
      params.push('validation_url=' + encodeParams(this.options.encodedURL));
    }

    if (this.validationCookie) {
      params.push('validation_cookie=' + encodeParams(this.validationCookie));
    }

    const paramsString = params.length ? '?' + params.join('&') : '';

    return socketURL + paramsString;
  }



  // web socket

  private socketHandlers: { [key: string]: (arg0: any) => void } = {
    offerResponse: this.onSocketOfferResponse.bind(this),
    remoteIceCandidate: this.onSocketRemoteIceCandidate.bind(this),
    qualityChange: this.onSocketQualityChange.bind(this),
    activeProfiles: this.onSocketActiveProfiles.bind(this),
    peerActiveProfiles: this.onSocketPeerActiveProfiles.bind(this),
    peerStarted: this.onSocketPeerStarted.bind(this),
    peerStopped: this.onSocketPeerStopped.bind(this),
    timeZero: this.onSocketTimeZero.bind(this),
    dataObjectBroadcast: this.onSocketDataObjectBroadcast.bind(this),
    dataObjectReceive: this.onSocketDataObjectReceive.bind(this),
    dataObjectMessageFailure: this.onSocketDataObjectMessageFailure.bind(this),
    dataObjectUpdateResponse: this.onSocketDataObjectUpdateResponse.bind(this),
    error: this.onSocketError.bind(this),
    init: this.onSocketInit.bind(this),
    close: this.onSocketClose.bind(this),
    bye: this.onSocketBye.bind(this),
    validationCookie: this.onSocketValidationCookie.bind(this),
  };

  private setupSocket () {
    this.socket.on(SocketEvent.OfferResponse, this.socketHandlers.offerResponse);
    this.socket.on(SocketEvent.RemoteIceCandidate, this.socketHandlers.remoteIceCandidate);
    this.socket.on(SocketEvent.QualityChange, this.socketHandlers.qualityChange);
    this.socket.on(SocketEvent.ActiveProfiles, this.socketHandlers.activeProfiles);
    this.socket.on(SocketEvent.PeerActiveProfiles, this.socketHandlers.peerActiveProfiles);
    this.socket.on(SocketEvent.PeerStarted, this.socketHandlers.peerStarted);
    this.socket.on(SocketEvent.PeerStopped, this.socketHandlers.peerStopped);
    this.socket.on(SocketEvent.TimeZero, this.socketHandlers.timeZero);
    this.socket.on(SocketEvent.DataObjectBroadcast, this.socketHandlers.dataObjectBroadcast);
    this.socket.on(SocketEvent.DataObjectReceive, this.socketHandlers.dataObjectReceive);
    this.socket.on(SocketEvent.DataObjectMessageFailure, this.socketHandlers.dataObjectMessageFailure);
    this.socket.on(SocketEvent.DataObjectUpdateResponse, this.socketHandlers.dataObjectUpdateResponse);
    this.socket.on(SocketEvent.Error, this.socketHandlers.error);
    this.socket.on(SocketEvent.Init, this.socketHandlers.init);
    this.socket.on(SocketEvent.Close, this.socketHandlers.close);
    this.socket.on(SocketEvent.Bye, this.socketHandlers.bye);
    this.socket.on(SocketEvent.ValidationCookie, this.socketHandlers.validationCookie);
  }

  private onSocketValidationCookie (message: EmitterMessage<string>) {
    this.validationCookie = message.data;
  }

  private async onSocketClose (message: EmitterMessage<CloseEvent>) {
    this.emit(RTSEvent.SUBSCRIBE_CONNECTION_CLOSED, message.data);
    if (this.state === SubscriberState.Subscribed) {
      this.failover();
    } else if (this.state === SubscriberState.Connecting) {
      this.state = SubscriberState.Unsubscribed;
    }
  }

  private async onSocketBye (message: EmitterMessage<SocketMessage.ByeMessage>) {
    if (this.state === SubscriberState.Subscribed) {
      this.failover();
    } else {
      await this.socket.close();

      const urls =  message.data.otherEdges.map((edge) => edge.socketURL);
      let url;
      try {
        url = await this.discovery.getBestEndpointUrl(this.streamName, urls);
      } catch (error) {
        this.state = SubscriberState.Unsubscribed;
        await this.close();
        this.emit(RTSEvent.SUBSCRIBE_FAIL, error);
        return;
      }

      this.socket.connect(this.addSocketParams(url));
    }
  }

  private onSocketOfferResponse (message: EmitterMessage<SocketMessage.OfferResponseMessage>) {
    const description = new RTCSessionDescription({
      type: 'answer',
      sdp: message.data.response,
    });
    this.peer.handleOfferResponse(description);
    this.emit(RTSEvent.WEBRTC_ANSWER, this.peer.getConnection().remoteDescription);
  }

  private onSocketRemoteIceCandidate (message: EmitterMessage<SocketMessage.RemoteIceCandidateMessage>) {
    const candidate = new RTCIceCandidate({
      sdpMLineIndex: message.data.index,
      candidate: message.data.candidate,
    });
    this.peer.handleRemoteIceCandidate(candidate);
    this.emit(RTSEvent.WEBRTC_CANDIDATE_REMOTE, candidate);
  }

  private onSocketQualityChange (message: EmitterMessage<SocketMessage.QualityChangeMessage>) {
    this.emit(RTSEvent.SUBSCRIBE_QUALITY_CHANGE, {
      activeRole: message.data.activeRole,
      activeVariant: message.data.activeVariant
    });
  }

  private onSocketActiveProfiles (message: EmitterMessage<SocketMessage.ActiveProfilesMessage>) {
    this.emit(RTSEvent.SUBSCRIBE_ACTIVE_PROFILES, {
      activeProfiles: message.data.activeProfiles,
    });
  }

  private onSocketPeerActiveProfiles (message: EmitterMessage<SocketMessage.PeerActiveProfilesMessage>) {
    this.emit(RTSEvent.SUBSCRIBE_PEER_ACTIVE_PROFILES, {
      activeProfiles: message.data.activeProfiles,
    });
  }

  private onSocketPeerStarted (message: EmitterMessage<SocketMessage.PeerStartedMessage>) {
    this.emit(RTSEvent.SUBSCRIBE_PEER_STARTED, {
      url: message.data.url,
    });
  }

  private onSocketPeerStopped (message: EmitterMessage<SocketMessage.PeerStoppedMessage>) {
    this.emit(RTSEvent.SUBSCRIBE_PEER_STOPPED, {
      url: message.data.url,
    });
  }

  private onSocketTimeZero (message: EmitterMessage<SocketMessage.TimeZeroMessage>) {
    const data = message.data;
    this.emit(RTSEvent.SUBSCRIBE_TIME_ZERO, {
      rtpTimestamp: data.rtpTimestamp,
    });
  }

  private onSocketDataObjectBroadcast (message: EmitterMessage<SocketMessage.DataObjectBroadcastMessage>) {
    const data = message.data;
    this.emit(RTSEvent.SUBSCRIBE_DATAOBJECT_BROADCAST,  {
      map: data.object.map,
      version: data.object.version,
    });
  }

  private onSocketDataObjectReceive (message: EmitterMessage<SocketMessage.DataObjectReceiveMessage>) {
    const data = message.data;
    this.emit(RTSEvent.SUBSCRIBE_DATAOBJECT_MESSAGE,  {
      sender: data.sender,
      msg: data.msg,
    });
  }

  private onSocketDataObjectMessageFailure (message: EmitterMessage<SocketMessage.DataObjectMessageFailureMessage>) {
    const data = message.data;
    this.emit(RTSEvent.SUBSCRIBE_DATAOBJECT_MESSAGE_FAILURE,  {
      reason: data.reason,
    });
  }

  private onSocketDataObjectUpdateResponse (message: EmitterMessage<SocketMessage.DataObjectUpdateResponseMessage>) {
    const data = message.data;
    this.emit(RTSEvent.SUBSCRIBE_DATAOBJECT_UPDATE_RESPONSE, {
      response: data.response,
      requestResponseCorrelationId: data.requestResponseCorrelationId,
    });
  }

  private onSocketError (error: Error) {
    this.rejectSubscribe(error);
  }

  private onSocketInit (emitterMessage: EmitterMessage<SocketMessage.InitMessage>) {
    const message = emitterMessage.data;

    this.state = SubscriberState.Connecting;

    this.emit(RTSEvent.CONNECT_SUCCESS, {
      activeVariant: message.activeVariant,
      audioOnly: message.audioOnly,
      thisEdge: message.thisEdge,
      traceId: message.traceId,
      validationCookie: message.validationCookie,
      variants: message.variants,
    });

    this.peer.init(this.options.rtcConfiguration, this.options.streamDataTimeoutSeconds);

    this.peer.createOffer({
      offerToReceiveVideo: !message.audioOnly,
      offerToReceiveAudio: true,
    }).then((offer) => {
      this.socket.handleOffer(offer);
      this.emit(RTSEvent.WEBRTC_OFFER, this.peer.getConnection().localDescription);
    }); // TODO catch error and emit event

    this.emit(RTSEvent.WEBRTC_PEERCONNECTION_AVAILABLE, this.peer.getConnection());
  }



  // peer connection

  private peerHandlers: { [key: string]: (arg: any) => void };

  private setupPeer () {
    this.peerHandlers = {
      localIceCandidate: this.onPeerLocalIceCandidate.bind(this),
      iceTrickleComplete: this.onPeerIceTrickleComplete.bind(this),
      streamAvailable: this.onPeerStreamAvailable.bind(this),

      timeout: this.onPeerTimeout.bind(this),
      timeoutRecovered: this.onPeerTimeoutRecovered.bind(this),
      closed: this.onPeerClosed.bind(this),
    };

    this.peer.on(PeerEvent.LocalIceCandidate, this.peerHandlers.localIceCandidate);
    this.peer.on(PeerEvent.IceTrickleComplete, this.peerHandlers.iceTrickleComplete);
    this.peer.on(PeerEvent.StreamAvailable, this.peerHandlers.streamAvailable);
    this.peer.on(PeerEvent.Timeout, this.peerHandlers.timeout);
    this.peer.on(PeerEvent.TimeoutRecovered, this.peerHandlers.timeoutRecovered);
    this.peer.on(PeerEvent.Closed, this.peerHandlers.closed);
  }

  private onPeerLocalIceCandidate (message: EmitterMessage<RTCIceCandidate>) {
    const candidate = message.data;
    this.socket.handleLocalIceCandidate(candidate);
    if (candidate) {
      this.emit(RTSEvent.WEBRTC_CANDIDATE_LOCAL, candidate);
    }
  }

  private onPeerStreamAvailable (message: EmitterMessage<MediaStream>) {
    const stream = message.data;
    this.el.srcObject = stream;
    this.emit(RTSEvent.WEBRTC_ADD_STREAM, stream);
  }

  private onPeerIceTrickleComplete (message: EmitterMessage<Event>) {
    const changeEvent = message.data;
    this.emit(RTSEvent.WEBRTC_ICETRICKLE_COMPLETE, changeEvent);
  }

  private onPeerTimeout () {
    if (this.state === SubscriberState.Subscribed) {
      this.roleTimeout = setTimeout(() => {
        this.peer.close(); // this will trigger a socket failover
      }, this.options.streamDataTimeoutSeconds * 1000);

      const newRole = this.getRole() === 'primary' ? 'backup' : 'primary';

      this.setRole(newRole);
    }
  }

  private onPeerTimeoutRecovered () {
    clearTimeout(this.roleTimeout);
  }

  private onPeerClosed (message: EmitterMessage<Event>) {
    if (this.state === SubscriberState.Subscribed) {
      this.failover();
    }
  }


  // video element

  private videoHandlers = {
    loadedmetadata: this.onVideoLoadedMetadata.bind(this),
    volumechange: this.onVideoVolumeChange.bind(this),
    play: this.onVideoPlay.bind(this),
    pause: this.onVideoPause.bind(this),
    fullscreenchange: this.onVideoFullscreenChange.bind(this),
    timeupdate: this.onVideoTimeUpdate.bind(this),
  };

  private bindVideoHandlers () {
    for (let [eventName, handler] of Object.entries(this.videoHandlers)) {
      this.el.addEventListener(eventName, handler);
    }
  }

  private unbindVideoHandlers () {
    for (let [eventName, handler] of Object.entries(this.videoHandlers)) {
      this.el.removeEventListener(eventName, handler);
    }
  }

  private async onVideoLoadedMetadata (e: Event) {
    this.resolveSubscribe(this);
    this.socket.startPing(this.options.pingIntervalSeconds);
    this.emit(RTSEvent.SUBSCRIBE_START, e);
    this.state = SubscriberState.Subscribed;

    await this.el.play().catch((error) => {
      this.emit(RTSEvent.SUBSCRIBE_AUTOPLAY_FAIL, e);
    });

  }

  private onVideoVolumeChange (event: Event) {
    this.emit(RTSEvent.SUBSCRIBE_VOLUME_CHANGE, {
      volume: this.muted ? 0 : this.el.volume,
      muted: this.muted,
    });
  }

  private onVideoPlay (event: Event) {
    this.emit(RTSEvent.SUBSCRIBE_PLAYBACK_CHANGE, {
      state: RTSState.PLAYBACK_PLAYING,
    });
  }

  private onVideoPause (event: Event) {
    this.emit(RTSEvent.SUBSCRIBE_PLAYBACK_CHANGE, {
      state: RTSState.PLAYBACK_PAUSED,
    });
  }

  private onVideoFullscreenChange (event: Event) {
    const isFullscreen = !!document.fullscreenElement;
    this.el.controls = isFullscreen;
    this.emit(RTSEvent.SUBSCRIBE_FULLSCREEN_CHANGE, { isFullscreen });
  }

  private onVideoTimeUpdate (event: Event) {
    this.emit(RTSEvent.SUBSCRIBE_TIME_UPDATE, {
      time: this.el.currentTime,
      duration: this.el.duration,
    });
  }
}
