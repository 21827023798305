export interface PublisherBandwidthOption {
  video?: number;
}

/** Options dictionary for Publisher initialization */
export class PublisherOptions {
  /** Media element for previewing the published video stream. May also be specified by id with mediaElementId */
  public mediaElement?: HTMLMediaElement;

  /** id of the preview media element. The element may also be specified with mediaElement */
  public mediaElementId?: string;

  /** WebSocket protocol */
  public protocol?: string = 'wss';

  /** WebSocket host to connect to */
  public host?: string;

  /** WebSocket port */
  public port?: number = 443;

  /** Short name */
  public shortName?: string;

  /** Name of the stream to publish */
  public streamName?: string;

  /** Rendition to publish, i.e. "mystream_500" */
  public rendition?: string;

  /** Username for stream publishing authorization */
  public username?: string;

  /** Password to authorize stream publishing */
  public password?: string;

  /** Legacy username and password parameters
   *
   * @ignore */
  public connectionParams: { username: string, password: string }; // legacy

  /** Seconds between socket pings and MediaVault cookie updates */
  public pingIntervalSeconds? = 15;

  /** Number of seconds to wait without receiving any stream data before triggering failover */
  public streamDataTimeoutSeconds? = 15;

  /** Maximum number of times to attempt failover */
  public maxFailoverAttempts? = Infinity;

  /** Maximum bandwidth limitation in kbps. Example: { video: 512 } */
  public bandwidth?: PublisherBandwidthOption = {};

  /** Number of seconds to wait for publishing to start before triggering a failure */
  public publishTimeoutSeconds? = 10;

  /** Number of seconds to wait for a publishing to start before triggering a faiure */
  public unpublishTimeoutSeconds? = 10;

  /** Function returning a MediaStream object to bypass automatic getUserMedia invocation */
  public onGetUserMedia?: (arg0: MediaStreamConstraints) => Promise<MediaStream>;

  /** Configuration object used to construct the RTCPeerConnection */
  public rtcConfiguration?: RTCConfiguration = {
    iceServers: [ {urls: 'stun:stun2.1.google.com:19302'} ],
    iceTransportPolicy: 'all',
    iceCandidatePoolSize: 2,
  };

  /** User media constraints for getUserMedia */
  public mediaConstraints: MediaStreamConstraints = {
    audio: true,
    video: {
      width: { exact: 640 }, height: { exact: 480 },
      frameRate: { min: 8, max: 24 },
    },
  };
}
