export interface DiscoveryOptions {
  protocol: string,
  host: string,
  port: number,
  shortName: string,
  retrySocketOnFirstFailure: boolean,
}

export interface DiscoveryResponse {
  urls: string[];
}

export class Discovery {

  private template = '{protocol}://{host}:{port}/public/discovery/v1/{shortName}/{streamName}';

  private urls: string[];
  private url: string;
  private failedUrls: { [key: string]: number } = {};

  private protocol: string;
  private host: string;
  private port: number;
  private shortName: string;
  private retrySocketOnFirstFailure: boolean;

  constructor (options: DiscoveryOptions) {
    this.init(options);
  }

  public init ({
    protocol,
    host,
    port,
    shortName,
    retrySocketOnFirstFailure,
  }: DiscoveryOptions) {
    this.protocol = protocol;
    this.host = host;
    this.port = port;
    this.shortName = shortName;
    this.retrySocketOnFirstFailure = retrySocketOnFirstFailure;
  }

  public failEndpoint () {
    const retry = this.retrySocketOnFirstFailure
      && this.failedUrls[this.url] === undefined;
    const failedAt = retry ? 0 : +new Date();
    this.failedUrls[this.url]  = failedAt;
  }

  public async getBestEndpointUrl (streamName: string, urls?: string[]) {
    let seeded=false;
    if (urls) {
      seeded=true;
      this.urls = urls;
    } else {
      this.urls = (await this.discoverEndpoints(streamName)).urls;
    }

    let bestUrl = '';
    let oldestFailedAt = 0;

    for (let i=0; i<this.urls.length; i++) {
      const url = this.urls[i];
      let failedAt = this.failedUrls[url] || 0;

      if (!bestUrl || failedAt < oldestFailedAt) {
        bestUrl = url;
        oldestFailedAt = failedAt;
      }
    }

    this.url = bestUrl;
    return this.url;
  }

  public endpointCount () {
    return this.urls.length;
  }

  private getDiscoveryUrl (streamName: string): string {
    return this.template
      .replace('{protocol}', this.protocol)
      .replace('{host}', this.host)
      .replace('{port}', String(this.port))
      .replace('{shortName}', this.shortName)
      .replace('{streamName}', streamName);
  }

  private async discoverEndpoints (streamName: string): Promise<DiscoveryResponse> {
    return new Promise ((
      resolve: (d: DiscoveryResponse) => void,
      reject: any
    ) => {
      const url = this.getDiscoveryUrl(streamName);
      const request = new XMLHttpRequest();

      request.onreadystatechange = () => {
        if (request.readyState == XMLHttpRequest.DONE) {
          if (request.status == 200) {
            resolve({ urls: JSON.parse(request.responseText) });
          } else {
            reject(request.responseText || request.statusText);
          }
        }
      };

      request.open('GET', url, true);
      request.send();
    });
  }

}
