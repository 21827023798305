import { Emitter, EmitterMessage } from '../emitter';
import { PublisherOptions } from './publisher-options';
import { Socket, SocketEvent, SocketMessage } from './socket';
import { Peer, PeerEvent } from './peer';
import { RTSEvent } from '../events';
import { PublisherData } from './data';
import { setMediaBitrate } from './set-media-bitrate';

export {
  PublisherData,
  PublisherOptions,
};

export enum PublisherState {
  Closed,
  Opening,
  Connected,
  Authenticated,
  Ingesting,
}

export class Publisher extends Emitter {

  private options: PublisherOptions;
  private state: PublisherState = PublisherState.Closed;
  private el: HTMLMediaElement;
  private stream: MediaStream;
  private rendition: string;
  private publishTimeout: ReturnType<typeof setTimeout>;
  private publishing: Promise<Publisher>;
  private rejectPublish: ((arg0: Error) => void) = () => {};
  private resolvePublish: ((arg0: Publisher) => void) = (s) => {};
  private socket: Socket = new Socket();
  private peer: Peer = new Peer();

  /** Data object and messaging interface */
  public data: PublisherData = new PublisherData(this.socket);

  /**
   * Create a new Publisher object to stream RTS content
   *
   * @param options Initialization parameters. May also be supplied later with the init method
   */

  constructor (
    options?: PublisherOptions
  ) {
    super();

    this.peer.on(PeerEvent.LocalIceCandidate, this.onPeerLocalIceCandidate.bind(this));
    this.socket.on(SocketEvent.RemoteIceCandidate, this.onSocketRemoteIceCandidate.bind(this));

    this.socket.on(SocketEvent.DataObjectBroadcast, this.onSocketDataObjectBroadcast.bind(this));
    this.socket.on(SocketEvent.DataObjectReceive, this.onSocketDataObjectReceive.bind(this));
    this.socket.on(SocketEvent.DataObjectUpdateResponse, this.onSocketDataObjectUpdateResponse.bind(this));

    this.socket.on(SocketEvent.Close, this.onSocketClose.bind(this));
    this.socket.on(SocketEvent.Error, this.onSocketError.bind(this));

    if (options) {
      this.init(options);
    }
  }

  /**
   * Initialize the media element and media devices before publishing.
   * The Publisher may also be initialized by passing options to the constructor
   * instead of calling this method directly
   *
   * @param options Publisher initialization parameters
   * */
  public async init (options: PublisherOptions) {
    this.options = { ...new PublisherOptions(), ...options };

    // fall back to legacy username/password params
    this.options.username = this.options.username
    || this.options.connectionParams.username;
    this.options.password = this.options.password
    || this.options.connectionParams.password;

    if (this.options.mediaElement) {
      this.el = this.options.mediaElement;
    } else if (this.options.mediaElementId) {
      this.el = document.getElementById(this.options.mediaElementId) as HTMLMediaElement;
    }

    // don't getUserMedia again on subsequent init() calls
    if (!this.stream) {
      // defer to onGetUserMedia param when provided
      if (this.options.onGetUserMedia) {
        this.stream = await this.options.onGetUserMedia(this.options.mediaConstraints);
      } else {
        this.stream = await navigator.mediaDevices.getUserMedia(this.options.mediaConstraints);
      }

      if (!this.stream) {
        throw new Error('getUserMedia MediaStream unavailable');
      }

      if (this.el) {
        this.el.srcObject = this.stream;
      }
    }

    return this;
  }

  /**
   * Start publishing the stream
   *
   * @param rendition If specified, overrides the original rendition specified
   * by the publisher initiailization options
   */
  public async publish (rendition?: string) {
    if (this.state === PublisherState.Ingesting) {
      await this.unpublish();
    }

    rendition = rendition || this.options.rendition;
    this.state = PublisherState.Opening;

    this.publishing = new Promise((resolve: (arg0: Publisher) => void, reject: (arg0: Error) => void) => {
      this.resolvePublish = (publisher: Publisher) => {
        clearTimeout(this.publishTimeout);
        this.emit(RTSEvent.PUBLISH_START);
        resolve(publisher);
      };

      this.rejectPublish = (error: Error) => {
        clearTimeout(this.publishTimeout);
        reject(error);
      }

      this.publishTimeout = setTimeout(() => {
       reject(new Error('Publish timed out after ' + String(this.options.publishTimeoutSeconds) + ' seconds'));
      }, this.options.publishTimeoutSeconds * 1000);
    });

    this.publishing.catch((error) => {
      if (this.state !== PublisherState.Closed) {
        this.state = PublisherState.Closed;
        this.emit(RTSEvent.PUBLISH_FAIL, error);
      }
    });

    this.socket.connect({
      host: this.options.host,
      port: this.options.port,
      protocol: this.options.protocol,
      shortName: this.options.shortName,
      rendition: rendition,
    });

    // TODO: if this never gets called, make sure it's unbound (otherwise it'll be called twice)
    this.socket.one(SocketEvent.Init, this.onSocketInit.bind(this));

    return this.publishing;
  }

  private async onSocketInit (message: EmitterMessage<SocketMessage.InitMessage>) {
    this.emit(RTSEvent.CONNECT_SUCCESS, {
      traceId: message.data.traceId,
    });

    this.peer.init(this.options.rtcConfiguration, this.stream);
    this.socket.one(SocketEvent.Authenticated, this.onSocketAuthenticated.bind(this));
    this.socket.authenticate(this.options.username, this.options.password);
    this.socket.startPing(this.options.pingIntervalSeconds);
  }

  private onSocketAuthenticated (message: EmitterMessage<SocketMessage.AuthenticatedMessage>) {
    this.state = PublisherState.Authenticated;
    this.startIngest();
  }

  private startIngest () {
    this.socket.one(SocketEvent.IngestStarted, this.onSocketIngestStarted.bind(this));
    this.socket.startIngest();
    this.state = PublisherState.Ingesting;
    this.resolvePublish(this);
  }

  private transformSdpBitrates (sdp: string) {
    if (this.options.bandwidth.video) {
      sdp = setMediaBitrate(sdp, 'video', this.options.bandwidth.video);
    }
    return sdp;
  }

  private async onSocketIngestStarted (message: EmitterMessage<SocketMessage.IngestStartedMessage>) {
    const offer = await this.peer.createOffer(this.transformSdpBitrates.bind(this));
    this.socket.one(SocketEvent.OfferResponse, this.onSocketOfferResponse.bind(this));
    this.socket.handleOffer(offer);
    this.emit(RTSEvent.WEBRTC_OFFER, this.peer.getConnection().localDescription);
  };

  private async onSocketOfferResponse (message: EmitterMessage<SocketMessage.OfferResponseMessage>) {
    let sdp = this.transformSdpBitrates(message.data.response);

    const description = new RTCSessionDescription({ type: 'answer', sdp });
    try {
      await this.peer.handleOfferResponse(description);
    } catch (e) {
      this.emit(RTSEvent.PUBLISH_FAIL, e);
    }
    this.emit(RTSEvent.WEBRTC_ANSWER, this.peer.getConnection().remoteDescription);
  }

  private onPeerLocalIceCandidate (candidate: EmitterMessage<RTCIceCandidate>) {
    this.socket.handleLocalIceCandidate(candidate.data);
    if (candidate) {
      this.emit(RTSEvent.WEBRTC_CANDIDATE_LOCAL, candidate);
    }
  }

  private onSocketRemoteIceCandidate (message: EmitterMessage<SocketMessage.RemoteIceCandidateMessage>) {
    const candidate = new RTCIceCandidate({
      sdpMLineIndex: message.data.index,
      candidate: message.data.candidate,
    });
    this.peer.handleRemoteIceCandidate(candidate);
    this.emit(RTSEvent.WEBRTC_CANDIDATE_REMOTE, candidate);
  }

  private onSocketDataObjectBroadcast (message: EmitterMessage<SocketMessage.DataObjectBroadcastMessage>) {
    this.emit(RTSEvent.SUBSCRIBE_DATAOBJECT_BROADCAST,  {
      map: message.data.object.map,
      version: message.data.object.version,
    });
  }

  private onSocketDataObjectReceive (message: EmitterMessage<SocketMessage.DataObjectReceiveMessage>) {
    this.emit(RTSEvent.SUBSCRIBE_DATAOBJECT_MESSAGE,  {
      sender: message.data.sender,
      msg: message.data.msg,
    });
  }

  private onSocketDataObjectUpdateResponse (message: EmitterMessage<SocketMessage.DataObjectUpdateResponseMessage>) {
    this.emit(RTSEvent.SUBSCRIBE_DATAOBJECT_UPDATE_RESPONSE, {
      response: message.data.response,
      requestResponseCorrelationId: message.data.requestResponseCorrelationId,
    });
  }

  private onSocketClose (event: CloseEvent) {
    if (this.state !== PublisherState.Closed) {
      this.state = PublisherState.Closed;
      this.emit(RTSEvent.PUBLISHER_CONNECTION_CLOSED, event.reason);
      this.rejectPublish(new Error("Socket connection failed"));
    }
  }

  private onSocketError (event: ErrorEvent) {
    if (this.state !== PublisherState.Closed) {
      this.emit(RTSEvent.CONNECT_FAILURE, event);
      this.state = PublisherState.Closed;
      this.rejectPublish(new Error("Socket connection failed"));
    }
  }

  /**
   * Stop publishing the stream and close connections
   */
  public async unpublish () {
    return new Promise<void>(async (resolve, reject) => {
      setTimeout(reject, this.options.unpublishTimeoutSeconds * 1000);
      if (!this.socket.closed) {
        await this.socket.stopIngest();
        await this.socket.close();
      }
      this.peer.close();
      this.emit(RTSEvent.UNPUBLISH_SUCCESS);
      this.state = PublisherState.Closed;
      resolve();
    });
  }
}
